export const nationality = [
  'Français(e)',
  'Afghan(e)',
  'Albanais(e)',
  'Algérien(ne)',
  'Allemand(e)',
  'Américain(e)',
  'Andorran(e)',
  'Angolais(e)',
  'Antiguais(e)-et-Barbudien(ne)',
  'Argentin(e)',
  'Arménien(ne)',
  'Australien(ne)',
  'Autrichien(ne)',
  'Azerbaïdjanais(e)',
  'Bahamien(ne)',
  'Bahreïnien(ne)',
  'Bangladais(e)',
  'Barbadien(ne)',
  'Belge',
  'Bélizien(ne)',
  'Béninois(e)',
  'Bhoutanais(e)',
  'Biélorusse',
  'Birman(e)',
  'Bissau-Guinéen(ne)',
  'Bolivien(ne)',
  'Bosnien(ne)',
  'Botswanais(e)',
  'Brésilien(ne)',
  'Britannique',
  'Brunéien(ne)',
  'Bulgare',
  'Burkinabé(e)',
  'Burundais(e)',
  'Cambodgien(ne)',
  'Camerounais(e)',
  'Canadien(ne)',
  'Cap-Verdien(ne)',
  'Centrafricain(e)',
  'Chilien(ne)',
  'Chinois(e)',
  'Chypriote',
  'Colombien(ne)',
  'Comorien(ne)',
  'Congolais(e) (République démocratique)',
  'Congolais(e) (République)',
  'Cookien(ne)',
  'Costaricain(e)',
  'Croate',
  'Cubain(e)',
  'Danois(e)',
  'Djiboutien(ne)',
  'Dominicain(e)',
  'Dominiquais(e)',
  'Égyptien(ne)',
  'Émirien(ne)',
  'Équatorien(ne)',
  'Érythréen(ne)',
  'Espagnol(e)',
  'Estonien(ne)',
  'Éthiopien(ne)',
  'Fidjien(ne)',
  'Finlandais(e)',
  'Gabonais(e)',
  'Gambien(ne)',
  'Géorgien(ne)',
  'Ghanéen(ne)',
  'Grec(que)',
  'Grenadien(ne)',
  'Guatémaltèque',
  'Guinéen(ne)',
  'Guinéen(ne)-Bissauvien(ne)',
  'Guyanien(ne)',
  'Haïtien(ne)',
  'Hondurien(ne)',
  'Hongrois(e)',
  'Indien(ne)',
  'Indonésien(ne)',
  'Iranien(ne)',
  'Iraqien(ne)',
  'Irlandais(e)',
  'Islandais(e)',
  'Israélien(ne)',
  'Italien(ne)',
  'Ivoirien(ne)',
  'Jamaïcain(e)',
  'Japonais(e)',
  'Jordanien(ne)',
  'Kazakhstani(e)',
  'Kényan(ne)',
  'Kirghize',
  'Kiribatien(ne)',
  'Koweïtien(ne)',
  'Laotien(ne)',
  'Lesothan(ne)',
  'Letton(ne)',
  'Libanais(e)',
  'Libérien(ne)',
  'Libyen(ne)',
  'Liechtensteinois(e)',
  'Lituanien(ne)',
  'Luxembourgeois(e)',
  'Malagasy',
  'Macédonien(ne)',
  'Malaisien(ne)',
  'Malawien(ne)',
  'Maldivien(ne)',
  'Mali(enne)',
  'Maltais(e)',
  'Marocain(e)',
  'Marshallais(e)',
  'Mauricien(ne)',
  'Mauritanien(ne)',
  'Mexicain(e)',
  'Micronésien(ne)',
  'Moldave',
  'Monacal(e)',
  'Mongol(e)',
  'Monténégrin(ne)',
  'Mozambicain(e)',
  'Namibien(ne)',
  'Nauruan(ne)',
  'Néerlandais(e)',
  'Néo-Zélandais(e)',
  'Népalais(e)',
  'Nicaraguayen(ne)',
  'Nigérien(ne)',
  'Nigérian(e)',
  'Niuéen(ne)',
  'Nord-coréen(ne)',
  'Norvégien(ne)',
  'Omanais(e)',
  'Ougandais(e)',
  'Ouzbèke',
  'Pakistanaise',
  'Palaosien(ne)',
  'Palestinien(ne)',
  'Panaméen(ne)',
  'Papouan(e)-Néo-Guinéen(ne)',
  'Paraguayen(ne)',
  'Péruvien(ne)',
  'Philippin(e)',
  'Polonais(e)',
  'Portugais(e)',
  'Qatarien(ne)',
  'Roumain(e)',
  'Russe',
  'Rwandais(e)',
  'Saint-Kitts-et-Nevisien(ne)',
  'Saint-Lucien(ne)',
  'Saint-Marinais(e)',
  'Saint-Martinois(e)',
  'Saint-Pierrais(e)',
  'Saint-Vincentais(e)',
  'Salomonais(e)',
  'Salvadorien(ne)',
  'Samoan(e)',
  'Santoméen(ne)',
  'Saoudien(ne)',
  'Sénégalais(e)',
  'Serbe',
  'Seychellois(e)',
  'Sierra-léonais(e)',
  'Singapourien(ne)',
  'Slovaque',
  'Slovène',
  'Somalien(ne)',
  'Soudanais(e)',
  'Sri-lankais(e)',
  'Suédois(e)',
  'Suisse',
  'Surinamien(ne)',
  'Syrien(ne)',
  'Tadjik(e)',
  'Taïwanais(e)',
  'Tanzanien(ne)',
  'Tchadien(ne)',
  'Tchèque',
  'Thaïlandais(e)',
  'Timorais(e)',
  'Togolais(e)',
  'Tonguien(ne)',
  'Trinidadien(ne)',
  'Tunisien(ne)',
  'Turc(que)',
  'Turkmène',
  'Tuvaluan(ne)',
  'Ukrainien(ne)',
  'Uruguayen(ne)',
  'Vanuatuan(ne)',
  'Vaticanais(e)',
  'Vénézuélien(ne)',
  'Vietnamien(ne)',
  'Wallisien(ne)',
  'Yéménite',
  'Zambien(ne)',
  'Zimbabwéen(ne)',
];
