/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { useLazyQuery } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  useIonLoading,
  useIonModal,
  useIonViewDidEnter,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ModalLayout } from 'src/components'
import ActionListItem from 'src/components/ActionListItem/ActionListItem'
import { SelectionTri } from 'src/components/Selection/SelectionTri'
import { GET_ACTIONS_LIST } from 'src/graphql/actions-types.graphql'
import { ActionEntity } from 'src/models/gql/graphql'
import {
  resetFicheActions,
  resetFilters,
} from 'src/stores/slices/ficherActionsSlices'
import store, { RootState } from 'src/stores/store'
import { getOrgId } from 'src/utils'
import styled from 'styled-components'

import './Actions.css'

/* interface GroupedActionEntity {
  count: number
  [key: string]: unknown // accepte les clés de type chaîne de caractères
}
export interface ActionEntityWithCount extends ActionEntity {
  count: number
} */

const Actions: React.FC = () => {
  const history = useHistory()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [getInitData, { loading, data }] = useLazyQuery(GET_ACTIONS_LIST)
  const [present, dismiss] = useIonModal(SelectionTri, {
    onDismiss: (dataModal: string, role: string) => dismiss(dataModal, role),
  })

  const { filters } = useSelector((state: RootState) => state.ficheActions)
  const [desc, setDesc] = useState('desc')

  const openModalTri = (): void => {
    present({
      onWillDismiss: (ev: CustomEvent<any>) => {
        if (ev.detail.role === 'confirm') {
          setDesc(`${ev.detail.data}`)
        }
      },
    })
  }

  const checkIfAnyFieldHasValue = (obj: Record<string, any>): boolean => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== '') {
          return true
        }
      }
    }
    return false
  }

  const getFieldsWithValues = (
    obj: Record<string, any>,
  ): Record<string, any> => {
    const fieldsWithValues: Record<string, any> = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== '') {
          fieldsWithValues[key] = obj[key]
        }
      }
    }
    return fieldsWithValues
  }

  useEffect(() => {
    const hasValue = checkIfAnyFieldHasValue(filters)

    if (hasValue) {
      const {
        usagerFirstName,
        usagerLastName,
        created_date,
        isUnknown,
        usagerLocalisation,
        usagerSituationFamiliale,
        services,
        categories,
        actions,
        createdBy,
      } = getFieldsWithValues(filters)

      getInitData({
        notifyOnNetworkStatusChange: true,
        variables: {
          filters: {
            and: [
              {
                organization: { id: { eq: getOrgId() } },
              },
              usagerFirstName
                ? { usagers: { firstname: { containsi: usagerFirstName } } }
                : {},

              usagerLastName
                ? { usagers: { lastname: { containsi: usagerFirstName } } }
                : {},
              usagerSituationFamiliale
                ? {
                    usagers: {
                      familyStatus: {
                        id: {
                          in: usagerSituationFamiliale,
                        },
                      },
                    },
                  }
                : {},

              usagerLocalisation
                ? { usagers: { addressZipcode: { eq: usagerLocalisation } } }
                : {},

              created_date ? { actionDate: { eq: created_date } } : {},
              createdBy ? { author: { id: { eq: createdBy } } } : {},
              isUnknown ? { nbUnknownUsagers: { gt: 0 } } : {},

              services
                ? {
                    service: {
                      id: {
                        in: services,
                      },
                    },
                  }
                : {},

              actions
                ? {
                    actionType: {
                      id: {
                        in: actions,
                      },
                    },
                  }
                : {},

              categories
                ? {
                    actionType: {
                      category: {
                        id: {
                          in: categories,
                        },
                      },
                    },
                  }
                : {},
            ],
          },

          pagination: { page: 1, pageSize: 150 },
        },
      })
    }
  }, [filters, getInitData])

  useIonViewDidEnter(() => {
    const hasValue = checkIfAnyFieldHasValue(filters)

    if (!hasValue) {
      getActionList()
    }
  })

  const getActionList = (): void => {
    getInitData({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            /* {
              author: { id: { eq: getUserId() } },
            }, */
          ],
        },
        sort: ['actionDate:DESC'],
        pagination: { page: 1, pageSize: 150 },
      },
    })
  }

  useEffect(() => {
    const {
      usagerFirstName,
      usagerLastName,
      created_date,
      isUnknown,
      usagerLocalisation,
      usagerSituationFamiliale,
      services,
      categories,
      actions,
      createdBy,
    } = getFieldsWithValues(filters)

    if (desc) {
      getInitData({
        notifyOnNetworkStatusChange: true,
        variables: {
          filters: {
            and: [
              {
                organization: { id: { eq: getOrgId() } },
              },
              usagerFirstName
                ? { usagers: { firstname: { containsi: usagerFirstName } } }
                : {},

              usagerLastName
                ? { usagers: { lastname: { containsi: usagerFirstName } } }
                : {},
              usagerSituationFamiliale
                ? {
                    usagers: {
                      familyStatus: {
                        id: {
                          in: usagerSituationFamiliale,
                        },
                      },
                    },
                  }
                : {},

              usagerLocalisation
                ? { usagers: { addressZipcode: { eq: usagerLocalisation } } }
                : {},

              created_date ? { actionDate: { eq: created_date } } : {},
              createdBy ? { author: { id: { eq: createdBy } } } : {},
              isUnknown ? { nbUnknownUsagers: { gt: 0 } } : {},

              services
                ? {
                    service: {
                      id: {
                        in: services,
                      },
                    },
                  }
                : {},

              actions
                ? {
                    actionType: {
                      id: {
                        in: actions,
                      },
                    },
                  }
                : {},

              categories
                ? {
                    actionType: {
                      category: {
                        id: {
                          in: categories,
                        },
                      },
                    },
                  }
                : {},
            ],
          },
          sort: [`actionDate:${desc.toUpperCase()}`],
          pagination: { page: 1, pageSize: 150 },
        },
      })
    }
  }, [desc, filters, getInitData])

  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      store.dispatch(resetFicheActions())
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  const handleClick = (): void => {
    history.push('/create/action')
  }
  const resetAllFilter = (): void => {
    store.dispatch(resetFilters())
    getActionList()
  }

  const usagersSumm = (): number => {
    const uniqueUserIds = new Set()
    // Parcourir chaque objet et compter les identifiants d'usager uniques
    data?.actions?.data.forEach((obj) => {
      const usagers = obj?.attributes?.usagers?.data
      usagers?.forEach((usager) => {
        uniqueUserIds.add(usager.id)
      })
    })

    const nbUnknownUsagersArray = data?.actions?.data.map(
      (objet) => objet?.attributes?.nbUnknownUsagers ?? 0, // Utilisation de l'opérateur de coalescence nulle pour attribuer 0 aux valeurs nulles
    )

    const somme =
      nbUnknownUsagersArray && nbUnknownUsagersArray?.length > 0
        ? nbUnknownUsagersArray.reduce(
            (acc: number, nbUnknownUsagers: number) =>
              acc + (nbUnknownUsagers ?? 0), // Utilisation de l'opérateur de coalescence nulle pour attribuer 0 aux valeurs nulles
            0,
          )
        : 0

    return (uniqueUserIds.size + somme) as number
  }

  const mergeUsagers = (): number => {
    // Utiliser un Set pour stocker les identifiants d'usager uniques avec chief:true
    const uniqueChiefIds = new Set()
    // Parcourir chaque objet et stocker les identifiants d'usager uniques ayant chief:true
    data?.actions?.data.forEach((obj) => {
      const usagers = obj?.attributes?.usagers?.data
      usagers?.forEach((usager) => {
        if (usager?.attributes?.chief === true) {
          uniqueChiefIds.add(usager.id)
        }
      })
    })

    return uniqueChiefIds.size
  }

  return (
    <ActionWrapper>
      <ModalLayout title="Liste des fiches actions">
        <IonGrid className="ion-no-padding fixed-actions">
          <IonRow className="fiche-actions">
            <IonCol className="ion-justify-content-center" size="4">
              <div className="list-actions">
                <IonIcon className="flag-action" />
                <h3 className="count">
                  {data && data.actions ? data.actions?.data.length : 0}
                </h3>
                <p className="subtitle">actions</p>
              </div>
            </IonCol>
            <IonCol className="ion-justify-content-center" size="4">
              <div className="list-actions">
                <IonIcon className="usagers-action" />
                <h3 className="count">{usagersSumm() || 0}</h3>
                <p className="subtitle">usagers</p>
              </div>
            </IonCol>
            <IonCol className="ion-justify-content-center" size="4">
              <div className="list-actions">
                <IonIcon className="action-home" />
                <h3 className="count">{mergeUsagers() || 0}</h3>
                <p className="subtitle">ménages</p>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="action-tabs">
            <IonCol>
              <div className="filter-action">
                <div
                  className={
                    checkIfAnyFieldHasValue(filters)
                      ? 'filter-button has-close'
                      : 'filter-button'
                  }
                >
                  <IonButton
                    color="primary"
                    size="small"
                    className="btn-filter-action"
                    onClick={() => history.replace('/actions/filters')}
                  >
                    Filtres{' '}
                  </IonButton>
                  {checkIfAnyFieldHasValue(filters) && (
                    <span className="filter-close" onClick={resetAllFilter}>
                      {checkIfAnyFieldHasValue(filters)
                        ? Object.keys(getFieldsWithValues(filters)).length
                        : ''}
                    </span>
                  )}
                </div>
                <div className="filter-button">
                  <IonButton
                    color="primary"
                    size="small"
                    className="btn-filter-action"
                    onClick={openModalTri}
                  >
                    Tri :{' '}
                    {desc === 'desc' ? 'plus récentes' : 'plus anciennes '}
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="liste-actions">
          <IonRow>
            <IonCol>
              <div>
                {data &&
                  data.actions &&
                  data.actions?.data.length > 0 &&
                  data.actions?.data.map((actions) => (
                    <ActionListItem
                      action={(actions as unknown) as ActionEntity}
                      key={actions.id}
                    />
                  ))}

                <IonFab onClick={handleClick} className="ion-fab">
                  <IonFabButton>
                    <IonIcon icon={add} />
                  </IonFabButton>
                </IonFab>
              </div>

              {!loading &&
                data &&
                data.actions &&
                data.actions?.data.length === 0 && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      {checkIfAnyFieldHasValue(filters) &&
                      Object.keys(getFieldsWithValues(filters)).length > 0
                        ? 'Aucun résultat trouvé pour vos filtres'
                        : "Vous n'avez pas d'actions"}
                    </IonCol>
                  </IonRow>
                )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </ModalLayout>
    </ActionWrapper>
  )
}

const ActionWrapper = styled(IonPage)`
  .usagers-segments {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 104px;
    background: var(--ion-color-main-background);
    padding: 15px 0 10px;
    border-radius: 24px 24px 0 0;
  }
  .usagers-segments-content {
    margin-top: 40px;
  }
`
export default Actions
