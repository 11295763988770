/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode, { JwtPayload } from 'jwt-decode';
// eslint-disable-next-line import/no-extraneous-dependencies
import isBefore from 'date-fns/isBefore';
import StorageUtils from './storage';

export const getApolloConfig = (): any => {
  const authToken = StorageUtils.get('authToken');
  let linkParams: any = { uri: process.env.REACT_APP_GRAPHQL_ENDPOINT };
  if (authToken && authToken !== 'null') {
    linkParams = {
      ...linkParams,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
  }
  return linkParams;
};

export const isAuthenticated = (): boolean => {
  const authToken = StorageUtils.get('authToken');
  if (!authToken) {
    return false;
  }

  const decoded = jwtDecode<JwtPayload>(authToken);
  if (!decoded.exp) {
    // error decoding
    return false;
  }
  const expiration = new Date(decoded.exp * 1000);
  if (isBefore(expiration, new Date())) {
    // expired = clear
    StorageUtils.clear()
    return false;
  }
  return true;
};

export const getUserData: any = () => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return null;
  }

  return JSON.parse(userData);
};

export const getUserId = (): string | undefined | null => {
  const authToken = StorageUtils.get('authToken');
  if (!authToken) {
    return null;
  }

  const decoded = jwtDecode<any>(authToken);
  if (!decoded.exp) {
    // error decoding
    return null;
  }
  return decoded.id;
};

export const getOrgId = (): string => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return '';
  }
  const data = JSON.parse(userData);

  if (data?.organization?.data?.id) {
    return data?.organization?.data?.id;
  }
  return '';
};

export const getServiceName = (): string => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return '';
  }
  const data = JSON.parse(userData);

  if (data?.services?.data?.length > 0) {
    return data?.services?.data.reduce((acc: any, curr: any, index: any) => {
      const name = curr.attributes.name.trim();
      if (index === 0) {
        return name;
      }
      return `${acc}-\n${name}`;
    }, '');
  }
  return '-';
};

export const checkHealthcareWorker = (): boolean => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return false;
  }
  const data = JSON.parse(userData);

  if (data?.healthcareWorker) {
    return true;
  }
  return false;
};

export const isAdmin = (): boolean => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return false;
  }
  const data = JSON.parse(userData);
  if (data?.role?.data?.attributes?.name?.includes('Admin')) {
    return true;
  }
  return false;
};

export const getPushTokenUser = (): string => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return '';
  }
  const data = JSON.parse(userData);

  console.log('data', data);

  if (data.pushToken) {
    return data.pushToken;
  }
  return '';
};
