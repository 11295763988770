import { useMutation } from '@apollo/client'
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router'
import { UPDATE_PROFILS } from 'src/graphql/auth.graphql'
import { StorageUtils } from 'src/utils/'
import { getPushTokenUser, getUserId } from 'src/utils/auth'
import { requestForToken } from 'src/utils/firebase'
import Actions from './Actions/Actions'
import DetailsActions from './Actions/Details/DetailsActions'
import Home from './Home/Home'
import Profils from './Profils/Profils'
import Transmissions from './Transmissions/Transmissions'
import CreateUsagers from './usagers/create/Create-usagers'
import DetailUsager from './usagers/detail/detail'
import Usagers from './usagers/usagers'

const MainTabs: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')
  const [updateProfils] = useMutation(UPDATE_PROFILS)

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location.pathname])

  const handleClick = (url: string): void => {
    history.replace(`/main/${url}`)
  }

  const update = useCallback(
    async (token: string) => {
      try {
        const result = await updateProfils({
          variables: {
            data: {
              pushToken: token,
            },
          },
        })

        const { data } = result
        if (data?.updateMe.data?.id) {
          StorageUtils.set(
            'userData',
            data?.updateMe?.data?.attributes,
          )
        }
      } catch (e) {
        console.error('error #1', e)
        throw e
      }
    },
    [updateProfils],
  )

  useEffect(() => {
      requestForToken()
        .then((currentToken: string | unknown) => {
          if (currentToken) {
            console.log('current token for client: ', currentToken)
            const tok = getPushTokenUser()
            if (tok !== currentToken) {
              // Perform any other necessary action with the token
              update(currentToken as string)
            }
            return currentToken
          }
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.',
          )
          return ''
        })
        .catch((err: any) => {
          console.log('An error occurred while retrieving token. ', err)
        })
  }, [update])
  return (
    <IonTabs>
      <IonRouterOutlet mode="md">
        <Route path="/main/:tab(home)" component={Home} exact />
        <Route path="/main/usagers" render={() => <Usagers />} exact />
        <Route
          path="/main/:tab(usagers)/create"
          component={CreateUsagers}
          exact
        />

        <Route path="/main/:tab(actions)" component={Actions} exact />
        <Route
          path="/main/:tab(actions)/:id"
          component={DetailsActions}
          exact
        />
        <Route
          path="/main/:tab(transmissions)"
          component={Transmissions}
          exact
        />
        <Route path="/main/:tab(profils)" component={Profils} exact />

        <Route exact path="/" render={() => <Redirect to="/tabs/home" />} />
        <Route
          path={[
            '/main/usagers/view/:id/',
            '/main/usagers/view/:id/fiche',
            '/main/usagers/view/:id/actions',
            '/main/usagers/view/:id/notes',
            '/main/usagers/view/:id/notes/create',
            '/main/usagers/view/:id/notes/view/:noteId',
            '/main/usagers/view/:id/health',
            '/main/usagers/view/:id/health/update/:healthId',
          ]}
          component={DetailUsager}
          exact
        />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className="footer-tabs">
        <IonTabButton
          tab="home"
          onClick={() => {
            handleClick('home')
          }}
          selected={currentPath === '/main/home'}
        >
          <IonIcon className="icon-home icon" />
          <IonLabel className="text-home">Accueil</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="usagers"
          selected={
            currentPath === '/main/usagers' || currentPath.includes('usagers')
          }
          onClick={() => {
            handleClick('usagers')
          }}
        >
          <IonIcon className="icon-usagers icon" />
          <IonLabel className="text-home">Usagers</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="actions"
          selected={currentPath.includes('/main/actions')}
          onClick={() => {
            handleClick('actions')
          }}
        >
          <IonIcon className="icon-actions icon" />
          <IonLabel className="text-home">Actions</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="transmissions"
          onClick={() => {
            handleClick('transmissions')
          }}
          selected={currentPath.includes('transmissions')}
        >
          <IonIcon className="icon-transmissions icon" />
          <IonLabel className="text-home">Transmissions</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="profils"
          selected={currentPath.includes('profils')}
          onClick={() => {
            handleClick('profils')
          }}
        >
          <IonIcon className="icon-profil icon" />
          <IonLabel className="text-home">Profil</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default MainTabs
