/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  useIonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import { useToast } from 'src/hooks'
import { arrowBack } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { AppDatepicker, ModalLayout } from 'src/components'
import ModalUsagerSelectedEdit from 'src/components/Modals/ConfirmModal/ModalUsagerSelectedEdit'
import Selection from 'src/components/Selection/Selection'
import UsagerListItem from 'src/components/UsagerListItem/UsagerListItem'
import {
  FETCH_ONE_ACTIONS,
  UPDATE_ACTION_TYPES,
} from 'src/graphql/actions-types.graphql'
import { ActionInput, UsagerEntity } from 'src/models/gql/graphql'
import { resetStepThree } from 'src/stores/slices/ficherActionsSlices'
import store, { RootState } from 'src/stores/store'
import {
  fetchOrientationData,
  fetchServiceData,
  fetchUsagers,
} from 'src/stores/thunks/fiche-actions-thunks'
import { formatDate, getOrgId, getUserId } from 'src/utils/'
import { formatDateInsertion } from 'src/utils/formatting'
import styled from 'styled-components'
import './DetailsActions.css'

interface IFormValues {
  date: string
  quantity: number
  nbUnknownUsagers: number
  orientation: string
  service: string
  description: string
  usagers?: UsagerEntity[]
  updatedBy: string
  updatedAt: Date
  userUpdated: string
}

const DetailsActions: React.FC = () => {
  const history = useHistory()
  const [modalSuccess, showModalSuccess] = useState(false)
  const [presentLoading, dismissLoading] = useIonLoading()
  const [getInitData, { loading, data }] = useLazyQuery(FETCH_ONE_ACTIONS)
  const [updateFicheActions, { loading: loadingUpdate, error }] = useMutation(
    UPDATE_ACTION_TYPES,
  )
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const [isAssign, setAssign] = useState<boolean>(false)
  const errorToast = useToast({text: `Une erreur s'est produite pendant le traitement, veuillez réessayer!`});

  const [formValues, setFormValues] = useState<IFormValues>({
    date: data?.action?.data?.attributes?.actionDate || '',
    quantity: 0,
    nbUnknownUsagers: 0,
    orientation: '',
    service: '',
    description: '',
    usagers: ([] as unknown) as UsagerEntity[],
    updatedBy: '',
    updatedAt: new Date(Date.now()),
    userUpdated: '',
  })

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value } = event.target

    setFormValues({ ...formValues, date: value || formValues.date })
  }

  const handleQuantityChange = (event: any): void => {
    if (event.target.value === '') {
      setFormValues({ ...formValues, quantity: parseInt('0', 10) })
    }
    setFormValues({ ...formValues, quantity: parseInt(event.target.value, 10) })
  }
  const submit = async (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
  ): Promise<void> => {
    try {
      const {data: updatedData, errors} = await updateFicheActions({
        variables: {
          id,
          data: {
            author: getUserId(),
            organization: getOrgId(),

            ...(formValues?.usagers &&
              formValues?.usagers?.length > 0 && {
                nbUnknownUsagers: 0,
              }),
            actionDate: formatDateInsertion(formValues.date).toString(),
            updatedUserBy: getUserId(),
            quantity: formValues?.quantity,
            service: formValues?.service,
            signalementDescription: formValues.description,
            orientationMadeBy: formValues.orientation,
            ...(formValues?.usagers &&
              formValues?.usagers?.length > 0 && {
                usagers: formValues?.usagers.map((res) => {
                  return res.id
                }),
              }),
          } as ActionInput,
        },
      })
      if (errors) {
        errorToast();
        throw errors;
      }
      history.replace(`/main/actions`)
      event.preventDefault()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }

  const { orientationList, usagersList, step_three, serviceList } = useSelector(
    (state: RootState) => state.ficheActions,
  )

  const handleBack = (): void => {
    setAssign(false)
    dispatch(resetStepThree())
    setupData()
  }

  useIonViewDidEnter(() => {
    getInitData({
      variables: { id },
    }).then((res) => {
      setupData()
      if (res.data && res.data.action) {
        setFormValues({
          date: res.data?.action?.data?.attributes?.actionDate,
          service: res.data?.action?.data?.attributes?.service?.data?.id || '',
          nbUnknownUsagers:
            res.data?.action?.data?.attributes?.nbUnknownUsagers || 0,
          description:
            (res.data &&
              res.data.action.data &&
              res.data?.action?.data.attributes?.signalementDescription) ||
            '',
          quantity: res.data?.action?.data?.attributes?.quantity || 0,
          usagers: res.data?.action?.data?.attributes?.usagers?.data,
          orientation:
            res.data?.action?.data?.attributes?.orientationMadeBy || '',
          updatedBy:
            res.data?.action?.data?.attributes?.updatedUserBy?.data?.id || '',
          updatedAt:
            res.data?.action?.data?.attributes?.updatedAt ||
            new Date(Date.now()),
          userUpdated: `Par ${res.data?.action?.data?.attributes?.updatedUserBy?.data?.attributes?.firstname} ${res.data?.action?.data?.attributes?.updatedUserBy?.data?.attributes?.lastname}`,
        })
      }
    })
  })

  useEffect(() => {
    if (step_three.length > 0) {
      setFormValues({ ...formValues, usagers: step_three })
    }
    if (step_three.length === 0 && formValues.nbUnknownUsagers > 0) {
      setFormValues({ ...formValues, usagers: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_three])

  const setupData = (): void => {
    store.dispatch(
      fetchServiceData({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            {
              users: { id: { in: getUserId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },

        sort: ['updatedAt:DESC'],
      }),
    )

    store.dispatch(
      fetchOrientationData({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )

    store.dispatch(
      fetchUsagers({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  useEffect(() => {
    if (loading || loadingUpdate) {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading, loadingUpdate, formValues.date])

  const handleClick = (): void => {
    history.replace('/main/actions')
    setFormValues({
      date: '',
      nbUnknownUsagers: 0,
      quantity: 0,
      orientation: '',
      description: '',
      usagers: ([] as unknown) as UsagerEntity[],
      updatedBy: '',
      updatedAt: new Date(Date.now()),
      userUpdated: '',
      service: '',
    })
    setAssign(false)
  }
  const handleDescriptionChange = (
    ev: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = ev.target as HTMLInputElement
    setFormValues({ ...formValues, description: value })
  }
  const handleOrientationChange = (
    event: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = event.detail
    if (value) {
      setFormValues({ ...formValues, orientation: value?.toString() })
    }
  }

  const handleServiceChange = (
    event: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = event.detail
    if (value) {
      setFormValues({ ...formValues, service: value?.toString() })
    }
  }

  return (
    <DetailsActionWrapper>
      <ModalLayout title="Fiche action" onBack={handleClick}>
        <IonGrid className="fixed-actions">
          <IonRow>
            <IonCol>
              <div className="details-actions">
                <div className="details-actions-wrap">
                  <div className="details-image">
                    {data?.action?.data?.attributes?.actionType?.data
                      ?.attributes?.category?.data?.attributes?.picto?.data
                      ?.attributes?.url ? (
                      <IonImg
                        src={
                          data?.action?.data?.attributes?.actionType?.data
                            ?.attributes?.category?.data?.attributes?.picto
                            ?.data?.attributes?.url
                        }
                        className="filter-orange"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <IonImg
                        src="/assets/images/doc.svg"
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                  </div>

                  <div className="details-actions-right">
                    <h3 className="title">
                      {data?.action?.data?.attributes?.actionType?.data
                        ?.attributes?.name || '-'}
                    </h3>

                    <p className="desc text-grey">
                      {formValues.nbUnknownUsagers &&
                      formValues.nbUnknownUsagers > 0
                        ? `${formValues.nbUnknownUsagers} inconnu${
                            formValues.nbUnknownUsagers > 1 ? 's' : ''
                          }`
                        : formValues &&
                          formValues?.usagers &&
                          formValues?.usagers?.length > 1
                        ? `${formValues?.usagers?.length} usagers`
                        : formValues?.usagers &&
                          `${formValues?.usagers[0]?.attributes?.firstname} ${
                            formValues?.usagers[0]?.attributes?.lastname || '-'
                          } `}
                    </p>
                  </div>
                </div>

                <div className="create-by">
                  <div className="create-by-image">
                    <IonImg
                      src="/assets/images/exclamation.svg"
                      style={{ width: 24, height: 24 }}
                    />
                  </div>

                  <div className="create-by-desc">
                    {data?.action?.data?.attributes?.author?.data?.id && (
                      <p>
                        Créée le {formatDate(formValues.date)} •{' '}
                        {getUserId()?.toString() ===
                        data?.action?.data?.attributes?.author?.data?.id
                          ? 'Par Moi'
                          : `Par ${data?.action?.data?.attributes?.author?.data?.attributes?.firstname} ${data?.action?.data?.attributes?.author?.data?.attributes?.lastname}`}
                      </p>
                    )}

                    {formValues.updatedBy && (
                      <p>
                        Modifiée le {formatDate(formValues.updatedAt)} •
                        {getUserId()?.toString() === formValues.updatedBy
                          ? ' Par Moi'
                          : formValues.userUpdated}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {!isAssign && (
          <IonGrid className="details-form">
            <IonRow>
              {data?.action?.data?.attributes?.quantity &&
              data?.action?.data?.attributes?.quantity > 0 ? (
                <IonCol size="4">
                  <IonItem>
                    <IonInput
                      label="Quantité"
                      value={formValues.quantity}
                      onInput={handleQuantityChange}
                      type="number"
                      labelPlacement="floating"
                    />
                  </IonItem>
                </IonCol>
              ) : (
                ``
              )}

              {formValues.date && (
                <IonCol size="8">
                  <AppDatepicker
                    preferWheel
                    name="birthDate"
                    label="Date de l’action"
                    presentation="date"
                    displayFormat="dd/MM/yyyy"
                    onChange={handleInputChange}
                    initialValue={formValues.date}
                  />
                </IonCol>
              )}
            </IonRow>
            <IonRow class="tabs-row">
              <IonCol>
                <IonText className="label-select">Service</IonText>
                <Selection
                  name="orientation"
                  mode="md"
                  value={formValues.service}
                  onChange={handleServiceChange}
                  fill="outline"
                  aria-label="service"
                  autocomplete
                  placeholder="Sélectionner un service"
                  items={
                    serviceList?.services.data &&
                    serviceList?.services.data.map((i) => ({
                      id: i?.id,
                      name: i.attributes?.name,
                    }))
                  }
                />
              </IonCol>
            </IonRow>
            <IonRow class="tabs-row">
              <IonCol>
                <IonText className="label-select">
                  Orientation effectuée par
                </IonText>

                <Selection
                  name="orientation"
                  mode="md"
                  value={formValues.orientation}
                  onChange={handleOrientationChange}
                  fill="outline"
                  aria-label="orientation"
                  autocomplete
                  placeholder="Sélectionner un signalement"
                  items={
                    orientationList?.orientationSources.data &&
                    orientationList?.orientationSources.data.map((i) => ({
                      id: i?.id,
                      name: i.attributes?.name,
                    }))
                  }
                />
              </IonCol>
            </IonRow>

            <IonRow class="tabs-row">
              <IonCol>
                <IonText className="label-select">
                  Description du signalement{' '}
                </IonText>
                <IonTextarea
                  autoGrow
                  value={formValues.description}
                  className="textarea-signal-action"
                  placeholder="Votre description..."
                  fill="solid"
                  name="message"
                  onIonInput={(e) => handleDescriptionChange(e)}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                {step_three.length === 0 ? (
                  <IonText className="text-action">
                    <span>
                      {formValues.nbUnknownUsagers > 0
                        ? formValues.nbUnknownUsagers
                        : formValues.usagers?.length}
                    </span>{' '}
                    {formValues.usagers && formValues.usagers.length > 1
                      ? `usager${
                          formValues.usagers.length > 1 ? 's' : ''
                        } lié à cette action`
                      : formValues.nbUnknownUsagers > 0
                      ? `inconnu${formValues.nbUnknownUsagers > 1 ? 's' : ''} ${
                          formValues.nbUnknownUsagers > 1
                            ? 'sont liés à cette action'
                            : 'est lié à cette action'
                        } `
                      : `usager lié à cette action`}{' '}
                  </IonText>
                ) : formValues?.usagers &&
                  formValues?.usagers?.length > 0 &&
                  formValues.nbUnknownUsagers > 0 ? (
                  <IonText className="text-action">
                    <span>
                      {step_three.length}{' '}
                      {step_three.length <= 1
                        ? ' usager à assigner'
                        : ' usagers à assigner'}
                    </span>{' '}
                    à cette action
                  </IonText>
                ) : (
                  ''
                )}
              </IonCol>
            </IonRow>

            {formValues.nbUnknownUsagers > 0 &&
            formValues &&
            formValues?.usagers &&
            formValues?.usagers?.length === 0 ? (
              <IonRow>
                <IonCol>
                  <div className="btn-assign">
                    <IonButton
                      color="primary"
                      size="small"
                      className="btn-small-white btn-small-border"
                      onClick={() => setAssign(!isAssign)}
                    >
                      {formValues.nbUnknownUsagers > 1
                        ? 'Assigner à des usagers'
                        : 'Assigner à un usager'}
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            ) : (
              formValues.usagers &&
              formValues.usagers.length > 0 &&
              formValues.usagers.map((res: UsagerEntity) => (
                <UsagerListItem usager={res} key={res.id} isFocus />
              ))
            )}
            <IonRow className="btn-register--row">
              <IonCol>
                <IonButton
                  color="primary"
                  expand="block"
                  size="large"
                  onClick={submit}
                >
                  Enregistrer les modifications
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {isAssign &&
          usagersList?.usagers?.data &&
          usagersList.usagers.data?.length > 0 && (
            <div className="usager-list">
              <IonGrid>
                <IonRow className="list-back">
                  <IonCol size="12">
                    <IonButton
                      className="inner-back-button"
                      fill="clear"
                      color="medium"
                      onClick={handleBack}
                    >
                      <IonIcon slot="start" icon={arrowBack} /> Retour
                    </IonButton>
                  </IonCol>
                  <IonCol size="12">
                    <IonText className="text-action">
                      Veuillez sélectionner {formValues.nbUnknownUsagers}
                      {formValues.nbUnknownUsagers &&
                      formValues.nbUnknownUsagers <= 1
                        ? ' inconnu'
                        : ' inconnus'}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonGrid>
                {usagersList.usagers.data.map((res: UsagerEntity) => (
                  <UsagerListItem
                    usager={res}
                    key={res.id}
                    isAction
                    isFocus
                    maxi={formValues.nbUnknownUsagers}
                  />
                ))}
              </IonGrid>
            </div>
          )}
        {step_three.length > 0 && isAssign && (
          <ModalUsagerSelectedEdit
            isOpen={step_three.length > 0}
            maxi={formValues.nbUnknownUsagers}
            onCancel={() => {
              showModalSuccess(false)
              setAssign(false)
            }}
          />
        )}
      </ModalLayout>
    </DetailsActionWrapper>
  )
}

const DetailsActionWrapper = styled(IonPage)`
  .usagers-segments {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 104px;
    background: var(--ion-color-main-background);
    padding: 15px 0 10px;
    border-radius: 24px 24px 0 0;
  }
  .usagers-segments-content {
    margin-top: 40px;
  }
`
export default DetailsActions
