import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonText,
  useIonLoading,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AuthLayout from 'src/components/Layouts/AuthLayout/AuthLayout'
import { LOGIN_MUTATION } from 'src/graphql/auth.graphql'
import { useToast } from 'src/hooks'
import { StorageUtils } from 'src/utils'
import { validateEmail, validateRequired } from 'src/utils/validations'

import { eye, eyeOff } from 'ionicons/icons'

const validators: ValidatorsType = {
  email: [validateRequired, validateEmail],
  password: [validateRequired],
}
const validatorsErros = {
  email: [
    'Ce champ est obligatoire',
    "Le format de l'adresse mail n'est pas valide",
  ],
  password: ['Ce champ est obligatoire'],
}

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [, setSubmit] = useState<boolean>(false)
  const [loginInput, setLoginInput] = useState<LoginForm>({
    email: '',
    password: '',
  })
  const [errorCustom, setErrorCustom] = useState<string>()
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [loginFn, { loading, error }] = useMutation(LOGIN_MUTATION)
  const [presentLoading, dismissLoading] = useIonLoading()
  const toast = useToast({ text: 'Vous êtes maintenant connecté!' })

  const [isTouched, setIsTouched] = useState<LoginFormValidation>({})
  const [isValid, setIsValid] = useState<LoginFormValidation>({})

  const history = useHistory()

  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  const setInputValue = (name: LoginFormKey, ev: Event): void => {
    const { value } = ev.target as HTMLInputElement
    setLoginInput((prev: LoginForm) => ({ ...prev, [name]: value }))
    validate(name)
  }
  const validate = (name: LoginFormKey, value?: any): boolean => {
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = loginInput[name]
    }

    if (validators[name] && validators[name].length > 0) {
      // we need to validate
      let falsyIndex = -1
      const isValidInput = validators[name].every((func, index) => {
        const shouldTrue = func(value)
        if (!shouldTrue) falsyIndex = index
        return shouldTrue
      })
      if (isValidInput) {
        setIsValid((prev) => ({ ...prev, [name]: true }))
        setFormErrors((prev) => ({ ...prev, [name]: undefined }))
        return true
      }
      setIsValid((prev) => ({ ...prev, [name]: false }))
      setFormErrors((prev) => ({
        ...prev,
        [name]: validatorsErros[name][falsyIndex],
      }))
      return false
    }
    setIsValid((prev) => ({ ...prev, [name]: true }))
    setFormErrors((prev) => ({ ...prev, [name]: undefined }))

    return true
  }

  const clickLogin = async (): Promise<void> => {
    const allValid = (['email', 'password'] as LoginFormKey[]).every((name) =>
      validate(name),
    )
    setIsTouched({ email: true, password: true })
    if (!allValid) {
      return
    }
    try {
      setSubmit(true)

      setErrorCustom(undefined)
      const { email: identifier, password } = loginInput
      const { data: response } = await loginFn({
        variables: { input: { identifier, password } },
      })
      if (response) {
        // just an error from graphql
        if (!response.login) {
          return
        }
        // if dont have organization
        if (!response.login.user.organization?.data) {
          setErrorCustom(
            "Vous n'avez pas encore de structure. Veuillez nous contacter!",
          )
          return
        }
        // if organization not validated
        if (!response.login.user.organization?.data?.attributes?.publishedAt) {
          setErrorCustom(
            'Votre compte a été désactivé. Merci de contacter les admins Paxtel.',
          )
          return
        }
        toast()
        StorageUtils.set('authToken', response?.login.jwt)
        StorageUtils.set('userData', response?.login.user)
        history.replace('/main/home')
      }
      setSubmit(false)
    } catch (e) {
      setSubmit(false)
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }

  const changeIconPassword = (): void => {
    setShowPassword(!showPassword)
  }

  return (
    <IonPage>
      <AuthLayout>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList lines="none">
                <IonItem>
                  <IonInput
                    className={`${isValid.email && 'ion-valid'} ${
                      isValid.email === false && 'ion-invalid'
                    } ${isTouched.email && 'ion-touched'}`}
                    label="Adresse email"
                    labelPlacement="stacked"
                    type="email"
                    placeholder="prénom@domain.com"
                    value={loginInput.email}
                    errorText={formErrors.email ? formErrors.email : undefined}
                    onIonInput={(e) => setInputValue('email', e)}
                    onIonBlur={() =>
                      setIsTouched((prev) => ({ ...prev, email: true }))
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    className={`${isValid.password && 'ion-valid'} ${
                      isValid.password === false && 'ion-invalid'
                    } ${isTouched.password && 'ion-touched'}`}
                    type={showPassword ? 'text' : 'password'}
                    errorText={
                      formErrors.password ? formErrors.password : undefined
                    }
                    label="Mot de passe"
                    labelPlacement="stacked"
                    value={loginInput.password}
                    onIonInput={(e) => setInputValue('password', e)}
                    onIonBlur={() =>
                      setIsTouched((prev) => ({ ...prev, password: true }))
                    }
                  />

                  <IonIcon
                    icon={showPassword ? eye : eyeOff}
                    slot="end"
                    onClick={() => changeIconPassword()}
                    style={{
                      zIndex: '999',
                      position: 'absolute',
                      right: '10px',
                      top: '25px',
                      cursor: 'pointer',
                    }}
                  />
                </IonItem>
                <IonRow>
                  <IonCol>
                    <Link to="/reset-password" className="ion-float-end">
                      Mot de passe oublié {showPassword}
                    </Link>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-text-center">
                    {error &&
                      (error?.message === 'Invalid identifier or password' ? (
                        <div className="error-text sc-ion-input-ios">
                          Adresse email ou mot de passe non valide.
                        </div>
                      ) : (
                        <div className="error-text sc-ion-input-ios">
                          Une erreur s'est produite: {error?.message}
                        </div>
                      ))}
                    {errorCustom && (
                      <div className="error-text sc-ion-input-ios">
                        <IonText>{errorCustom}</IonText>
                      </div>
                    )}
                  </IonCol>
                </IonRow>

                <IonButton
                  disabled={!!errorCustom}
                  expand="block"
                  onClick={clickLogin}
                  color="primary"
                  size="large"
                >
                  Se connecter
                </IonButton>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </AuthLayout>
    </IonPage>
  )
}

export default Login
