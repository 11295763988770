/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import {
  IonButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonText,
  useIonLoading,
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ActionTypeItem from 'src/components/ActionsTypes/ActionsTypes'
import FicheActionListItem from 'src/components/FicheActionListItem/FicheActionListItem'
import { ActionType } from 'src/models/gql/graphql'
import { resetActionTypeSelected } from 'src/stores/slices/ficherActionsSlices'
import {
  groupByCategory,
  Item,
} from 'src/stores/slices/provider/usagers/usagers.service'
import store, { RootState } from 'src/stores/store'
import { fetcCategoriesActionsData } from 'src/stores/thunks/fiche-actions-thunks'
import { getOrgId } from 'src/utils'
import styled from 'styled-components'
import './stepper.css'

export interface StepTwoProps {
  isSearch?: boolean
  resultSearch?: ActionType[]
}

const StepTwo: React.FC<StepTwoProps> = ({ isSearch, resultSearch }) => {
  const [presentLoading, dismissLoading] = useIonLoading()
  const {
    actionsList,
    actionTypeSelected,
    loading,
    selectActionType,
    serviceUserConnected,
  } = useSelector((state: RootState) => state.ficheActions)

  const services = (serviceUserConnected as unknown) as any

  useEffect(() => {
    initForm()
  }, [])

  useEffect(() => {
    if (loading === 'pending') {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  const initForm = (): void => {
    store.dispatch(
      fetcCategoriesActionsData({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            {
              services: {
                id: {
                  in: services?.usersPermissionsUser?.data?.attributes?.services?.data.map(
                    (res: any) => {
                      return res?.id
                    },
                  ),
                },
              },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  const handleBack = (): void => {
    store.dispatch(resetActionTypeSelected())
  }

  return (
    <StepTwoWrapper>
      <IonGrid>
        <IonRow className="ion-no-padding">
          {actionsList?.actionCategories.data &&
            actionsList?.actionCategories?.data.length > 0 &&
            !isSearch &&
            actionTypeSelected.actionTypes?.data &&
            actionTypeSelected.actionTypes?.data.length === 0 &&
            !selectActionType &&
            actionsList?.actionCategories?.data.map((action) => (
              <FicheActionListItem
                name={action.attributes?.name}
                icon={action.attributes?.picto?.data?.attributes?.url}
                idCategory={action.id || ''}
                key={action.id}
              />
            ))}{' '}
        </IonRow>

        {selectActionType &&
          actionTypeSelected.actionTypes?.data &&
          actionTypeSelected.actionTypes?.data.length === 0 && (
            <>
              {' '}
              <IonButton
                className="inner-back-button"
                fill="clear"
                color="medium"
                onClick={handleBack}
              >
                <IonIcon slot="start" icon={arrowBack} /> Retour
              </IonButton>
              <div className="ion-text-center">
                <IonLabel color="medium">
                  <IonText className="no-result">
                    La catégorie ne possède aucune action.
                  </IonText>
                </IonLabel>
              </div>
            </>
          )}

        {isSearch &&
          resultSearch &&
          resultSearch.length === 0 &&
          loading !== 'pending' && (
            <div className="ion-text-center">
              <IonLabel color="medium">
                <IonText className="no-result">Aucun résultat trouvé</IonText>
              </IonLabel>
            </div>
          )}

        {isSearch && resultSearch && resultSearch.length > 0 && (
          <IonLabel className="list-card-category">
            {Object.keys(
              groupByCategory((resultSearch as unknown) as Item[]),
            ).map((categoryName: string) => (
              <ActionTypeItem
                categoriesName={categoryName}
                key={categoryName}
                categorieImage={
                  groupByCategory((resultSearch as unknown) as Item[])[
                    categoryName
                  ][0].attributes?.category?.data?.attributes?.picto?.data
                    ?.attributes?.url
                }
                items={
                  groupByCategory((resultSearch as unknown) as Item[])[
                    categoryName
                  ]
                }
                isSearch
              />
            ))}
          </IonLabel>
        )}

        {actionTypeSelected.actionTypes.data &&
          actionTypeSelected.actionTypes?.data.length > 0 && (
            <IonLabel className="list-card-category">
              <ActionTypeItem
                categoriesName={
                  actionTypeSelected.actionTypes?.data[0].attributes?.category
                    ?.data?.attributes?.name
                }
                items={
                  (actionTypeSelected.actionTypes?.data as unknown) as Item[]
                }
                categorieImage={
                  actionTypeSelected.actionTypes?.data[0].attributes?.category
                    ?.data?.attributes?.picto?.data?.attributes?.url
                }
              />
            </IonLabel>
          )}

        {!isSearch &&
          loading !== 'pending' &&
          actionsList?.actionCategories.data &&
          actionsList?.actionCategories?.data.length === 0 && (
            <div className="ion-text-center">
              <IonLabel color="medium">
                <IonText>Aucune action n'est liée à votre organisation</IonText>
              </IonLabel>
            </div>
          )}
      </IonGrid>
    </StepTwoWrapper>
  )
}
const StepTwoWrapper = styled.div.attrs(() => ({
  className: useSelector((state: RootState) =>
    !state.ficheActions.selectActionType
      ? 'list-card'
      : 'list-card list-card-category',
  ),
}))`
  ion-searchbar {
    .searchbar-input {
      --padding-start: 5px;
    }
  }
`

export default StepTwo
