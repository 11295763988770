import { graphql } from 'src/models/gql';

export const LIST_ACTION_TYPES = graphql(/* GraphQL */ `
  query actionTypes(
    $filters: ActionTypeFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    actionTypes(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          name
          quantityManageable
          category {
            data {
              id
              attributes {
                iconClass
                name
                picto {
                  data {
                    attributes {
                      url
                      previewUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`);

export const GET_ACTIONS_LIST = graphql(/* GraphQL */ `
  query actions(
    $filters: ActionFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    actions(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          orientationMadeBy
          comment
          signalementDescription
          actionType {
            data {
              id
              attributes {
                name
                category {
                  data {
                    attributes {
                      picto {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          service {
            data {
              id
              attributes {
                name
              }
            }
          }
          usagers {
            data {
              id
              attributes {
                email
                firstname
                lastname
                chief
              }
            }
          }
          quantity
          nbUnknownUsagers
          actionDate
        }
      }
    }
  }
`);

export const CREATE_ACTION_MUTATION = graphql(/* GraphQL */ `
  mutation createAction($data: ActionInput!) {
    createAction(data: $data) {
      data {
        id
        attributes {
          usagers {
            data {
              id
            }
          }
        }
      }
    }
  }
`);

export const UPDATE_ACTION_TYPES = graphql(/* GraphQL */ `
  mutation updateAction($id: ID!, $data: ActionInput!) {
    updateAction(id: $id, data: $data) {
      data {
        id
        attributes {
          updatedAt
          updatedUserBy {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }
          orientationMadeBy
          comment
          signalementDescription
          actionType {
            data {
              id
              attributes {
                name
              }
            }
          }
          service {
            data {
              id
              attributes {
                name
              }
            }
          }
          usagers {
            data {
              id
              attributes {
                email
                firstname
                lastname
                birthDate
                sexe
                phone
                addressCity
              }
            }
          }
          quantity
          nbUnknownUsagers
          actionDate
          author {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  }
`);

export const FETCH_ONE_ACTIONS = graphql(/* GraphQL */ `
  query fetchOneAction($id: ID!) {
    action(id: $id) {
      data {
        id
        attributes {
          updatedAt
          updatedUserBy {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }
          orientationMadeBy
          comment
          signalementDescription
          actionType {
            data {
              id
              attributes {
                name
                category {
                  data {
                    attributes {
                      picto {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          service {
            data {
              id
              attributes {
                name
              }
            }
          }
          usagers {
            data {
              id
              attributes {
                email
                firstname
                lastname
                birthDate
                sexe
                phone
                addressCity
                age
              }
            }
          }
          quantity
          nbUnknownUsagers
          actionDate
          author {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  }
`);
