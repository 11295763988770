import React, { useState } from 'react';
import { IonButton, IonCol,  IonContent,  IonItem,   IonRadio,  IonRadioGroup,  IonRow,  IonSelect,  IonSelectOption,  IonText} from '@ionic/react';
import styled from 'styled-components';
import AppStartDatePicker from 'src/components/AppStartDatePicker/AppStartDatePicker';
import { useMutation } from '@apollo/client';
import { EXPORT_MUTATION } from 'src/graphql/export.graphql';
import { calendarClearOutline } from 'ionicons/icons';
import { useToast } from 'src/hooks';

const periods: IdNameEntity[] = [
  {id: 'today', name: 'Aujourd\'hui'},
  {id: 'this-week', name: 'Cette semaine'},
  {id: 'last-week', name: 'La semaine dernière'},
  {id: 'current-month', name: 'Ce mois ci'},
  {id: 'last-month', name: 'Le mois dernier '},
  {id: 'current-quarter', name: 'Ce trimestre'},
  {id: 'last-semester', name: 'Le trimestre dernier'},
  {id: 'current-semester', name: 'Ce semestre '},
  {id: 'current-year', name: 'Cette année'},
  {id: 'custom', name: 'Intervalle personnalisé'},
];


const ExportModalStep1: React.FC<{
  onSubmit: (e: any) => void,
  onCancel: (e: any) => void
  onNoData: (e: any) => void
}> = ({
  onSubmit,
  onCancel,
  onNoData
}) => {
  const [period, setPeriod] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const errorToast = useToast({
    position: 'bottom',
    text: 'Une erreur s\'est produite, veuillez réessayer plutard!'
  })

  const [
    exportFunction,
    { loading, error },
  ] = useMutation(EXPORT_MUTATION)

  const submit = async (e: any) => {
    try {
      const {data} = await exportFunction({variables: {input: {
        type,
        period,
        startDate,
        endDate
      }}})
      if (data?.export?.status === 'nodata') {
        onNoData(e);
      } else if (data?.export?.status === 'error') {
        errorToast();
      } else {
        onSubmit(e)
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
      <ExportModalStep1Wrapper>
          <IonRow   className="ion-padding">
            <IonCol className="ion-text-center">
              <div><h2>Parametrer l'export</h2></div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="label-select">
                Période
              </IonText>
              <IonItem>
                <IonSelect
                  mode="md"
                  interface="popover"
                  fill="outline"
                  value={period}
                  onIonChange={(e) => setPeriod(e.detail.value)}
                  aria-label="Sexe"
                  placeholder="Période"
                >
                  <IonSelectOption value="">
                  Sélectionner une période
                  </IonSelectOption>
                  {periods.map(i => <IonSelectOption key={i.id} value={i.id}>{i.name}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          {period === 'custom' && <IonRow className="rangepicker">
            <IonCol>
              <AppStartDatePicker 
              initialValue={startDate}
              className="range start"
              label="A partir du" 
              name="start"
              displayFormat="dd MMMM yyyy"
              presentation="date"
              max={endDate}
              onChange={(e) => setStartDate(e.detail.value)}
              />
            </IonCol>
            <IonCol>
              <AppStartDatePicker 
              initialValue={endDate}
              className="range end"
              label="jusqu'au" 
              name="end"
              icon={{icon: calendarClearOutline, slot: 'end'}}
              min={startDate}
              displayFormat="dd MMMM yyyy"
              presentation="date"
              onChange={(e) => setEndDate(e.detail.value)}
              />
            </IonCol>
          </IonRow>}
          <IonRow   className="ion-padding-horizontal">
            <IonCol className="history__title">
              <h4>Sélectionnez le contenu de l'export</h4>
              <IonRadioGroup value={type} onIonChange={(e) => setType(e.detail.value)}>
                <IonRadio labelPlacement="end" mode="md" value="actions">Fiches actions</IonRadio><br/>
                <IonRadio labelPlacement="end" mode="md"  value="usagers">Usagers</IonRadio><br />
                <IonRadio labelPlacement="end" mode="md"  value="notes">Notes</IonRadio><br />
              </IonRadioGroup>
            </IonCol>
          </IonRow>
          <IonRow  className="ion-padding-top">
            <IonCol>
                <IonButton
                  disabled={loading ||!type ||!period ||(period === 'custom' && (!startDate ||!endDate))}
                  color="primary"
                  onClick={submit}
                  expand="block">
                    Confirmer
                  </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton 
                color="primary"
                onClick={onCancel}
                fill="clear"
                expand="block">
                  Annuler
                </IonButton>
            </IonCol>
          </IonRow>
      </ExportModalStep1Wrapper>
)};
const ExportModalStep1Wrapper = styled(IonContent) `
ion-row.rangepicker {
  ion-col:first-of-type {
    padding-right: 0;
    margin-right: 0;
    --padding-end: 0;
  }
  ion-col:last-of-type {
    padding-left: 0;
    margin-left: 0;
    --padding-start: 0;
    
  }
}
  ion-radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
export default ExportModalStep1;