import { IonContent, IonHeader, IonImg, IonText } from '@ionic/react'
import { ReactElement } from 'react'
import { getUserData } from 'src/utils'

export type LayoutProps = {
  children: React.ReactNode
}

export default function AuthLayout({ children }: LayoutProps): ReactElement {
  const user = getUserData()

  const formatServices = (data: any): string => {
    return data.reduce((acc: any, curr: any, index: any) => {
      const name = curr.attributes.name.trim()
      if (index === 0) {
        return name
      }
      return `${acc}-\n${name}`
    }, '')
  }

  return (
    <>
      {/* <IonContent className={!isPassword ? 'login' :'login-password-step'}> */}
      <IonHeader
        className="login header-login"
        no-border
        style={{ boxShadow: 'none !important' }}
      >
        <div className="banner">
          <IonImg
            className="banner__image"
            src="/assets/images/mask.png"
            alt="Paxtel"
          />
        </div>
        <div className="logued-container">
          <IonText color="light">
            <h3>Bonjour {user?.firstname},</h3>
          </IonText>
          <IonText color="light">
            {user?.organization?.data?.attributes?.companyName} •{' '}
            {user?.services?.data?.length > 0
              ? formatServices(user?.services?.data)
              : '-'}
          </IonText>
        </div>
      </IonHeader>

      <IonContent className="login-content" scrollY={false}>
        {children}
      </IonContent>

      {/* </IonContent> */}
    </>
  )
}
