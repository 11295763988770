import { useLazyQuery, useMutation} from "@apollo/client";
import { 
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
  IonTextarea,
  useIonLoading,
  useIonViewDidEnter
 } from "@ionic/react"
import { ConfirmModal, InfoCard, ValidatedIcon } from "src/components";
import { arrowBack, trashBinOutline } from "ionicons/icons"
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { DELETE_NOTE_MUTATION, FETCH_ONE_NOTE, UPDATE_NOTE_MUTATION } from "src/graphql/usager-notes.graphql";
import { validateRequired } from "src/utils/validations";
import styled from "styled-components";
import { useToast } from "src/hooks";
import { formatDate, formatUserName } from "src/utils";

const validators: ValidatorsType = {
  description: [validateRequired],
  noteType: [validateRequired],
}
const validatorsErros = {
  description: ['Le champ description est obligatoire'],
  noteType: ['Vous devez choisir un type'],
}

interface FormErrors {
  [key: string]: string|undefined|null
}

const ViewNote: React.FC = () => {
  const { id: usagerId, noteId} = useParams<{ id: string, noteId: string }>()
  const [present, dismiss] = useIonLoading();
  const toaster = useToast({text: 'Note usager supprimée avec succès !', position: 'bottom'})

  const [getNote, {
    loading: loadingNote,
    data: dataNote,
  }] = useLazyQuery(FETCH_ONE_NOTE, {
    notifyOnNetworkStatusChange: true,
    variables: {id: noteId}
  });
  const [edition, setEdition] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [updateNote, {loading, error} ] = useMutation(UPDATE_NOTE_MUTATION)
  const [deleteNote, {loading: loadingDelete, error: errorDelete} ] = useMutation(DELETE_NOTE_MUTATION)
  const [formValues, setFormValues] = useState<NoteForm>({
    noteType: '',
    description: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [isTouched, setIsTouched] = useState<NoteFormValidation>({})
  const [isValid, setIsValid] = useState<NoteFormValidation>({})
  const history = useHistory();

  useEffect(() => {
    if (loading ||loadingDelete ||loadingNote) {
      present()
    } else {
      dismiss()
    }
    return () => {
      dismiss();
    }
  }, [loading, loadingDelete, loadingNote, present, dismiss])
  const validate = (name: keyof NoteForm, value?: any): boolean => {
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = formValues[name]
    }
    if (validators[name] && validators[name].length > 0) {
      // we need to validate
      let falsyIndex = -1;
      const isValidInput = validators[name].every((func, index) => {
        const shouldTrue = func(value);
        if (!shouldTrue) falsyIndex = index;
        return shouldTrue
      })
      if (isValidInput) {
        setIsValid((prev) => ({ ...prev, [name]: true }))
        setFormErrors((prev) => ({ ...prev, [name]:  undefined}))
        return true
      }
      setIsValid((prev) => ({ ...prev, [name]: false }))
      setFormErrors((prev) => ({ ...prev, [name]:  validatorsErros[name][falsyIndex]}))
      return false
    }
    setIsValid((prev) => ({ ...prev, [name]: true }))
    setFormErrors((prev) => ({ ...prev, [name]:  undefined}))
    return true
  }

  const submit = async (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): Promise<void> => {
    const allValid = Object.keys(validators).every((name) =>
      validate(name as keyof NoteForm),
    )
    setIsTouched({ noteType: true, description: true })
    if (!allValid) {
      return;
    }
    try {
      const result = await updateNote({
        variables: {
          id: noteId,
          data: {
            usager: usagerId,
            noteType: formValues.noteType,
            description: formValues.description,
          },
        },
      })

      const { data } = result
      if (data?.updateUsagerNote?.data?.id) {
        history.replace(`/main/usagers/view/${usagerId}/notes`)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }
  useEffect(() => {
    if (dataNote) {
      setFormValues({
        noteType: dataNote?.usagerNote?.data?.attributes?.noteType?.data?.id||'',
        description: dataNote?.usagerNote?.data?.attributes?.description||''
      })
    }
  }, [dataNote])

  const handleChange = (key: keyof NoteForm) => (
    event: CustomEvent<InputChangeEventDetail>,
  ) => {
    const { value } = event.detail
    setFormValues((prevValues) => ({ ...prevValues, [key]: value }))
    validate(key, value);
  }

  const confirmActions = {
    onCancel: () => setOpenConfirmation(false),
    onConfirm: async () => {
      setOpenConfirmation(false);
      await deleteNote({variables: {
        id: noteId
      }})
      toaster();
      history.replace(`/main/usagers/view/${usagerId}/notes`);
    }
  }

  useIonViewDidEnter(async () => {
    await getNote()
  })
  return <ViewNoteWrapper>
        <IonButton className="inner-back-button" fill="clear" color="medium"  onClick={() => history.replace(`/main/usagers/view/${usagerId}/notes`)}>
          <IonIcon slot="start" icon={arrowBack}/> Retour
        </IonButton>
    <IonRow>
      <IonCol>
        <div className="d-flex ion-align-items-center note-title">
          <h3 color="dark"> {dataNote?.usagerNote?.data?.attributes?.noteType?.data?.attributes?.name} </h3>
        </div>
      </IonCol>
    </IonRow>

    <IonRow>
        <IonCol>
        <InfoCard>
            <ul>
              <li>
                Créée le {formatDate(dataNote?.usagerNote?.data?.attributes?.createdAt)} • Par {formatUserName(dataNote?.usagerNote?.data?.attributes?.author?.data?.attributes)}
              </li>
            {dataNote?.usagerNote?.data?.attributes?.updatedAt !== dataNote?.usagerNote?.data?.attributes?.createdAt && <li>
                Modifiée le {formatDate(dataNote?.usagerNote?.data?.attributes?.updatedAt)} • Par {formatUserName(dataNote?.usagerNote?.data?.attributes?.author?.data?.attributes)}
              </li>}
            </ul>
        </InfoCard>
        </IonCol>
      </IonRow>
      <IonRow>
          <IonCol className={!edition ? 'ion-padding': ''}>
              <IonText className="label-select">Description</IonText>
                <IonTextarea
                  readonly={!edition}
                  autoGrow
                  cols={6}
                  value={formValues.description}
                  className={!edition ? 'disabled' : ''}
                  aria-label="Description"
                  placeholder="Description"
                  fill="solid"
                  errorText={formErrors.description ? formErrors.description : undefined}
                  onIonInput={handleChange('description')}
                 />
          </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
            {!edition && <IonButton
                  expand="block"
                  onClick={() => setEdition(true)}
                  color="primary"
                  size="large"
                >
                  Modifier
                </IonButton>}
            {edition && <IonButton
                  disabled={!!error}
                  expand="block"
                  onClick={submit}
                  color="primary"
                  size="large"
                >
                  Enregistrer
                </IonButton>}
        </IonCol>
      </IonRow>
      {!edition && <IonRow>
        <IonCol>
          <IonButton
              expand="block"
              onClick={() => setOpenConfirmation(true)}
              color="primary"
              size="small"
              fill="clear"
              className="btn-delete"
                >
                  Supprimer
                </IonButton>
        </IonCol>
      </IonRow>}
      <ConfirmModal
        isOpen={openConfirmation}
        icon={<ValidatedIcon icon={trashBinOutline} warningColor  height={50} />}
        title={`Etes-vous sûr de supprimer la note "${dataNote?.usagerNote?.data?.attributes?.noteType?.data?.attributes?.name}"?`}
        okText="Confirmer"
        onConfirm={confirmActions.onConfirm}
        onCancel={confirmActions.onCancel}/>

  </ViewNoteWrapper>
}

const ViewNoteWrapper = styled('div') `
padding: 0 10px 10px;

ion-textarea:not(.disabled) {
  opacity: 1;
  border: 1px solid #444141;
  border-radius: 8px;
  padding: 0 8px;
  transform: none !important; 
}
ion-select[disabled=true] {
  opacity: 1;
  --background: white;
  --border-width: 0;
}
ion-button.back {
  --padding-start: 0;
}
`
export default ViewNote
